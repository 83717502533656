<template>
    <div class="view-chats">
        <chat-window
            :current-user-id="currentUserId"
            :rooms="roomsFormatted"
            :messages="messagesFormatted"
            :rooms-loaded="roomsLoaded"
            :messages-loaded="messagesLoaded"
            :message-actions="messageActions"
            :text-messages="textMessages"
            :show-search="false"
            :show-add-room="false"
            :show-audio="false"
            :show-emojis="false"
            :show-reaction-emojis="false"
            :show-files="false"
            @fetch-messages="fetchMessages"
            @send-message="sendMessage"
            @edit-message="editMessage"
            @delete-message="deleteMessage"
        />
    </div>
</template>

<script>
    import ChatWindow from 'vue-advanced-chat'
    import 'vue-advanced-chat/dist/vue-advanced-chat.css'

    import moment from 'moment'; 
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc } from '@/helpers';
    import { CHAT_MESSAGE_STATUS } from '@/vars'; 

    export default {
        name: 'Chats',
        metaInfo: {
            title: 'Мои чаты'
        },
        components: {
            ChatWindow
        },
        data() {
            return {
                activeChatId: null,
                updateTimerId: null,
                roomsLoaded: false,
                messagesLoaded: false,
                messageActions: [
                    {
                        name: 'editMessage',
                        title: 'Изменить',
                        onlyMe: true
                    },
                    {
                        name: 'deleteMessage',
                        title: 'Удалить',
                        onlyMe: true
                    },
                ],
                textMessages: {
                    ROOMS_EMPTY: 'Активных чатов не найдено',
                    ROOM_EMPTY: 'Здесь еще никто ничего не написал',
                    NEW_MESSAGES: 'Новые сообщения',
                    MESSAGE_DELETED: 'Сообщение удалено',
                    MESSAGES_EMPTY: 'Сообщений не найдено',
                    CONVERSATION_STARTED: '',
                    TYPE_MESSAGE: 'Сообщение',
                    SEARCH: 'Поиск',
                    IS_ONLINE: 'Онлайн',
                    LAST_SEEN: 'Последний просмотр',
                    IS_TYPING: 'Печатает...',
                    CANCEL_SELECT_MESSAGE: 'Отменить'
                }
            }
        },
        computed: {
            ...mapState ('auth', {
                currentUserId: state => state.user._id
            }),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            ...mapState ('chats', ['chats', 'messages']),
            roomsFormatted() {
                return this.chats ? this.chats.map(item => {
                    const this_ = this;
                    const organizer = this.trips.filter(trip => (trip._id === item.trip._id)).length > 0;
                    const secondUser = item.users.filter(user => (user._id !== this_.currentUserId))[0];
                    return {
                        roomId: item._id,
                        roomName: organizer ? `${ secondUser.firstname? secondUser.firstname : ''} ${ secondUser.lastname? secondUser.lastname[0] : '' }. - ${ item.trip.name }` : `${ item.trip.name } - ${ secondUser.firstname } ${ secondUser.lastname[0] }.`,
                        avatar: organizer ? imageSrc(secondUser.avatar) : imageSrc(item.trip.pictures[item.trip.mainPictureIndex]),
                        unreadCount: item.messages.filter(message => (message.user !== this_.currentUserId && message.status !== CHAT_MESSAGE_STATUS.READED)).length,
                        // index: 3,
                        lastMessage: item.messages.length ? {
                            content: item.messages[item.messages.length - 1].text,
                            senderId: item.messages[item.messages.length - 1].user,
                            // username: 'John Doe',
                            timestamp: moment(item.messages[item.messages.length - 1].createdAt).format('HH:mm'),
                            saved: true,
                            distributed: [CHAT_MESSAGE_STATUS.DELIVERED, CHAT_MESSAGE_STATUS.READED].indexOf(item.messages[item.messages.length - 1].status) >= 0,
                            seen: item.messages[item.messages.length - 1].status === CHAT_MESSAGE_STATUS.READED,
                            // new: true
                        } : {},
                        users: item.users.map(user => {
                            return {
                                _id: user._id,
                                username: user.firstname,
                                avatar: imageSrc(user.avatar),
                                // status: {
                                //     state: 'online',
                                //     lastChanged: 'today, 14:30'
                                // }
                            }
                        })
                    }
                }) : [];
            },
            messagesFormatted() {
                return this.messages ? this.messages.map(item => {
                    return {
                        _id: item._id,
                        // indexId: 12092,
                        content: item.text,
                        senderId: item.user._id,
                        username: item.user.firstname,
                        avatar: imageSrc(item.user.avatar),
                        date: moment(item.createdAt).format('DD.MM'),
                        timestamp: moment(item.createdAt).format('HH:mm'),
                        // system: false,
                        saved: true,
                        distributed: [CHAT_MESSAGE_STATUS.DELIVERED, CHAT_MESSAGE_STATUS.READED].indexOf(item.status) >= 0,
                        seen: item.status === CHAT_MESSAGE_STATUS.READED,
                        // deleted: false,
                        // failure: true,
                        // disableActions: false,
                        // disableReactions: false,
                        // files: [
                        //     {
                                
                        //     }
                        // ],
                        // reactions: {},
                        // replyMessage: {
                        //     content: 'Reply Message',
                        //     senderId: 4321,
                        //     files: [
                        //         {
                                    
                        //         }
                        //     ]
                        // },
                    }
                }) : [];
            }
        },
        async beforeRouteEnter(to, from, next) {
            await store.commit('chats/CLEAR_ENTITY');
            next();
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            await store.dispatch('trips/fetch', { filter: { user: this.currentUserId } });
            await this.getData();
            this.updateTimerId = setInterval(async () => {
                await this.getData();
            }, 5000);
            
            this.roomsLoaded = true;
        },
        
        beforeDestroy () {
            clearInterval(this.updateTimerId);
        },
        methods: {
            async getData() {
                await store.dispatch('chats/fetch', {});
                if(this.activeChatId) {
                    await store.dispatch('chats/fetchMessages', { chatId: this.activeChatId });
                }
            },
            async fetchMessages({ room, options }) {
                this.messagesLoaded = false;
                this.activeChatId = room.roomId;
                await this.getData();
                this.messagesLoaded = true;
            },
            async sendMessage({ roomId, content }) {
                await store.dispatch('chats/sendMessage', { chatId: roomId, userId: this.currentUserId, content });
            },
            async editMessage({ roomId, messageId, newContent }) {
                await store.dispatch('chats/editMessage', { chatId: roomId, messageId, content: newContent });
            },
            async deleteMessage({ roomId, message }) {
                await store.dispatch('chats/deleteMessage', { chatId: roomId, messageId: message._id });
            }
        }
    }
</script>

<style lang="scss">
.view-chats {
    .vac-rooms-container {
        min-width: 30%;
        .vac-room-list {
            padding: 20px 5px 0 10px;
        }
        .vac-room-item {
            min-height: 65px;
            padding: 0 10px;
            .vac-avatar {
                height: 50px;
                width: 50px;
            }
        }
    }
    .vac-room-container .vac-room-badge {
        background-color: #de155e;
    }
    .vac-badge-counter {
        width: 20px;
        height: 20px;
    }
    .vac-col-messages {
        width: 70%;
    }
    .vac-rooms-container .vac-room-selected, 
    .vac-rooms-container .vac-room-selected:hover {
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%), #FFFFFF !important;
        #vac-icon-checkmark{
            fill: #FFFFFF !important;
        }
        #vac-icon-double-checkmark-seen {
            fill: #FFFFFF !important;
        }
        .vac-room-container .vac-room-name {
            color: #FFFFFF;
        }
        .vac-text-ellipsis {
            color: #FFFFFF;
        }
        .vac-text-date {
            color: #FFFFFF;
        }
    }
    .vac-room-header {
        box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 2px 16px rgba(44, 47, 56, 0.08);
        border-bottom: none;
            @media all and(min-width: 1024px) {
                .vac-toggle-button {
                margin: 0;
                svg {
                    display: none;
                }

            } 
        }
    }
    .vac-col-messages .vac-container-scroll {
        background: #FFFFFF;
    }
    .vac-message-wrapper .vac-message-card {
        background-color: #f7f7f7 !important;
        color: #273155;
    }
    .vac-message-wrapper .vac-message-current {
        background-color: #f7f7f7 !important;
        color: #273155;
    }
    .vac-message-actions-wrapper .vac-options-me {
        background: #dadada !important;
    }
    #vac-icon-checkmark-seen, #vac-icon-double-checkmark-seen {
        fill: #de155e;
    }
    #vac-icon-send {
        fill: #de155e;
    }
}
</style>